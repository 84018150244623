/* ****************** */
/* TABLET SCREEN SIZE */
/* ****************** */
@media (max-width: 1280px) {
  .filter-button {
    border-radius: 6px;
    padding: 0.5rem 2rem;
    width: fit-content;
  }
}

@media (max-width: 1024px) {
  .filter-button {
    padding: 0.5rem 1.5rem;
    font-size: 14px;
    margin: 0.2rem;
  }
}

@media (max-width: 640px) {
  .swiper-pagination-bullet {
    width: var(
      --swiper-pagination-bullet-width,
      var(--swiper-pagination-bullet-size, 15px)
    ) !important;
    height: var(
      --swiper-pagination-bullet-height,
      var(--swiper-pagination-bullet-size, 15px)
    ) !important;
  }
}

/* ***************** */
/* PHONE SCREEN SIZE */
/* ***************** */
@media (max-width: 450px) {
  .filter-button {
    padding: 0.3rem 1rem;
    font-size: 12px;
  }
}

@media (max-width: 380px) {
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 5px !important;
  }
  .swiper-pagination-bullet {
    width: var(
      --swiper-pagination-bullet-width,
      var(--swiper-pagination-bullet-size, 12px)
    ) !important;
    height: var(
      --swiper-pagination-bullet-height,
      var(--swiper-pagination-bullet-size, 12px)
    ) !important;
  }
}

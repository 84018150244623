@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* ********* */
/* VARIABLES */
/* ********* */
:root {
  --primary-color: white;
  --secondary-color: rgb(239, 68, 68);
  --tertiary-color: #141f1f;
  --blur-color: #000000aa;

  --primary-transition: all 0.3s ease-in-out;

  --animation-transition: 0.5s ease-in-out infinite;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #111827;
  border-radius: 100px;
}

::-webkit-scrollbar-track {
  background-color: var(--primary-color);
}

/* ******************** */
/* FONT FAMILY & WEIGHT */
/* ******************** */
.primary-font {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}

.secondary-font {
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

/* ****************** */
/* PROJECTS CONTAINER */
/* ****************** */
.project-card-container {
  transform-style: preserve-3d;
}

.project-card-title:before {
  content: "";
  width: 0%;
  height: 2px;
  position: absolute;
  bottom: -1px;
  left: 0;
  border-radius: 10px;
  background-color: var(--secondary-color);
  transition: var(--primary-transition);
}

.project-card-container:hover .project-card-title:before {
  width: 100%;
}

/* ********************** */
/* ***POPUP CONTAINER**** */
/* ********************** */
.popup-close-icon {
  border-bottom-left-radius: 10px;
}

/* ****************************** */
/* MORE DETAILS PROJECT CONTAINER */
/* ****************************** */
.more-details-project-name:before {
  content: "";
  width: 0%;
  height: 2px;
  position: absolute;
  bottom: -3px;
  left: 0;
  border-radius: 5px;
  background-color: var(--secondary-color);
  transition: var(--primary-transition);
}

.popup-content:hover .more-details-project-name:before {
  width: 100%;
}

/* **************** */
/* FILTER CONTAINER */
/* **************** */
.filter-button {
  cursor: pointer;
  background: none;
  outline: none;
  border: 3px solid rgb(254 226 226);
  border-radius: 8px;
  padding: 0.5rem 0;
  width: 12rem;
  background-color: white;
  margin: 0.5rem;
  transition: var(--primary-transition);
}

.filter-button:hover {
  background-color: white;
  border-color: rgb(252 165 165);
}

.filter-button.active {
  background-color: white;
  border-color: var(--secondary-color);
}

/* *********************************** */
/* OVERRIDING SOME CSS FROM THE SWIPER */
/* *********************************** */
.swiper-pagination-bullet {
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 20px)
  ) !important;
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 20px)
  ) !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: var(--primary-color) !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-weight: bold;
}

/* *************** */
/* LOADING SPINNER */
/* *************** */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  animation: spinner 1s linear infinite;
}

.loading-title {
  font-family: "Courier New", Courier, monospace;
}

/* *********************** */
/* SEARCH RECORD CONTAINER */
/* *********************** */
.crud-search-container::after,
.crud-search-container::before {
  content: "";
  position: absolute;
  bottom: -1px;
  border-radius: 100px;
  width: 0;
  height: 1px;
  background-color: var(--secondary-color);
  transition: var(--primary-transition);
}

.crud-search-container::before {
  left: 0%;
}

.crud-search-container::after {
  right: 0%;
}
.crud-search-container:focus-within {
  border-bottom-color: transparent;
  transition: var(--primary-transition);
}
.crud-search-container:focus-within::after,
.crud-search-container:focus-within::before {
  width: 50%;
}

.crud-search-container:focus-within .search-icon {
  color: var(--secondary-color);
}

.crud-search-container:focus-within .crud-search-input-title {
  top: -30% !important;
  color: var(--secondary-color);
}

/* ********************* */
/* EDIT DELETE CONTAINER */
/* ********************* */
@keyframes shakeIcon {
  0% {
    transform: rotate(-15deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}

.edit-icon:hover,
.delete-icon:hover {
  animation: shakeIcon var(--animation-transition);
}

/* *********************** */
/* ALREADY LOGIN CONTAINER */
/* *********************** */
.already-login--title {
  border-top-right-radius: 8px;
}

.already-login-img {
  border-radius: 10px 0px 0px 10px;
}

.already-login {
  border-radius: 0px 10px 10px 0px;
}
